<template>
  <div>
    <vx-card title="Status Customer Prospek" :is-refresh="isLoading">

      <div class="flex space-x-2 items-center mb-5">
        <div class="flex-1">
          <ProyekList class="w-full" @proyekRefreshed="params.id_proyek = $event; getData();" />
        </div>
        <div class="flex-1">
          <vs-select class="w-full vs-select-small" v-model="params.period" @input="onChangePeriod($event)">
            <vs-select-item class="vs-select-small" v-for="(it, index) in months" :key="index" :value="it.value" :text="it.name"/>
          </vs-select>
        </div>
      </div>

      <div class="overflow-y-auto" style="height: 415pt">
        <template v-if="data.queryResult.length > 0">
          <div slot="no-body">
            <!-- CHART -->
            <vue-apex-charts type="pie" height="285" class="mt-10 mb-8" :options="data.chartData.chartOptions" :series="data.chartData.series" />

            <!-- CHART DATA -->
            <ul class="mb-1 border border-solid border-l-0 border-r-0 border-t-0 d-theme-border-grey-light">
              <li v-for="item in data.queryResult" :key="item.status" class="flex justify-between py-3 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0">
                <span class="flex items-center">
                    <span class="inline-block h-3 w-3 rounded-full mr-2" :class="`bg-${item.status_color}`"></span>
                    <span class="font-semibold">{{ item.status }}</span>
                </span>
                <span>{{ item.jml_status }}</span>
              </li>
            </ul>
          </div>
        </template>

        <!--empty state-->
        <div v-if="data.queryResult.length < 1 && !isLoading" class="w-full h-full flex flex-col items-center justify-center">
          <img alt="" class="w-32 opacity-75" src="@/assets/svg/no-data.svg"/>
          <p class="text-sm mt-3">No data.</p>
        </div>
      </div>

    </vx-card>
  </div>
</template>

<script>
import ReportRepository from '@/repositories/reports/report-repository'
import VueApexCharts from 'vue-apexcharts'
import ProyekList from '@/views/pages/dashboard/ProyekList'
import moment from 'moment/moment'

export default {
  name: 'StatusCustomerProspek',
  components: {
    VueApexCharts,
    ProyekList
  },
  mounted () {
    this.initData()
  },
  data () {
    return {
      isLoading: false,
      params: {
        id_proyek: null,
        period: null,
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD')
      },
      data: {
        queryResult: [],
        chartData: {
          series: [],
          chartOptions: {}
        }
      },
      months: [
        { value: null, name: 'This Year' },
        { value: 1, name: 'Januari' },
        { value: 2, name: 'Pebruari' },
        { value: 3, name: 'Maret' },
        { value: 4, name: 'April' },
        { value: 5, name: 'Mei' },
        { value: 6, name: 'Juni' },
        { value: 7, name: 'Juli' },
        { value: 8, name: 'Agustus' },
        { value: 9, name: 'September' },
        { value: 10, name: 'Oktober' },
        { value: 11, name: 'Nopember' },
        { value: 12, name: 'Desember' }
      ]
    }
  },
  methods: {
    initData () {
      this.getData()
    },

    onChangePeriod (monthInt) {
      let startDate = null
      let endDate = null
      if (monthInt === null) {
        startDate = moment().startOf('year')
        endDate = moment().endOf('year')
      } else {
        const currentYear = moment().format('YYYY')
        startDate = moment(`${currentYear}-${monthInt}-01`, 'YYYY-M-DD')
        endDate = startDate.clone().endOf('month')
      }

      this.params.start_date = startDate.format('YYYY-MM-DD')
      this.params.end_date = endDate.format('YYYY-MM-DD')

      this.getData()
    },

    getData () {
      this.isLoading = true

      const reportId = 68
      const params = { params: this.params }
      return ReportRepository.templateData(reportId, params)
        .then(response => {
          this.data = response.data
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>
